* {
  box-sizing: border-box;
}

body {
  background-color: #eee;
  margin: 0;
  font-family: "Montserrat", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Montserrat", sans-serifsource-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.access_denied {
  color: red;
}
